import {
  FormEvent,
  KeyboardEvent,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import classNames from 'classnames';

import { ENDPOINTS } from 'graphql/queries';
import { UPDATE_ENDPOINT } from 'graphql/mutations';

import { useToggle } from 'hooks/useToggle';
import { validateUrl } from 'utils/validateUrl';

import { IEndpoint } from 'components/sidebar/Sidebar';

export const RedirectSettings = () => {
  const [isNotificationActive, setIsNotificationActive] = useState(false);
  const [isErrorNotificationActive, setIsErrorNotificationActive] =
    useState(false);
  const { state: isTipActive, toggle: toggleIsTipActive } = useToggle(false);
  const { endpointId } = useParams();

  const { data } = useQuery(ENDPOINTS, { fetchPolicy: 'cache-only' });
  const endpoint: IEndpoint = data.endpoints.find(
    ({ id }: IEndpoint) => id === endpointId
  );

  const [redirectUrlInput, setRedirectUrlInput] = useState(
    endpoint?.redirectUrl
  );

  useEffect(() => setRedirectUrlInput(endpoint?.redirectUrl), [endpoint]);

  const [updateEndpoint] = useMutation(UPDATE_ENDPOINT, {
    update: (
      cache,
      {
        data: {
          updateEndpoint: { redirectUrl: updatedRedirectUrl },
        },
      }
    ) => {
      cache.writeQuery({
        query: ENDPOINTS,
        data: {
          endpoints: [
            ...data.endpoints.filter(({ id }: IEndpoint) => endpointId !== id),
            {
              id: endpointId,
              name: endpoint.name,
              redirectUrl: updatedRedirectUrl,
            },
          ],
        },
      });
    },
  });

  const showNotification = useCallback(() => {
    if (isNotificationActive) return setIsNotificationActive(false);

    setIsNotificationActive(true);

    setTimeout(() => setIsNotificationActive(false), 3000);
  }, [isNotificationActive]);

  const showErrorNotification = useCallback(() => {
    if (isErrorNotificationActive) return setIsErrorNotificationActive(false);

    setIsErrorNotificationActive(true);

    setTimeout(() => setIsErrorNotificationActive(false), 3000);
  }, [isErrorNotificationActive]);

  const handleChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      setRedirectUrlInput(e.currentTarget.value);
    },
    [setRedirectUrlInput]
  );

  const handleSave = useCallback(() => {
    try {
      if (redirectUrlInput !== endpoint.redirectUrl) {
        if (!validateUrl(redirectUrlInput)) {
          showErrorNotification();
          setRedirectUrlInput(endpoint.redirectUrl);
          return;
        }

        updateEndpoint({
          variables: {
            where: { id: endpointId },
            data: { redirectUrl: { set: redirectUrlInput } },
          },
          optimisticResponse: {
            updateEndpoint: {
              id: endpoint.id,
              name: endpoint.name,
              redirectUrl: redirectUrlInput,
            },
          },
        });
      }
    } catch {}

    showNotification();
  }, [
    endpoint,
    endpointId,
    redirectUrlInput,
    showErrorNotification,
    showNotification,
    updateEndpoint,
  ]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <div className="settings__redirect redirect">
      <div
        className={classNames('notification', {
          active: isNotificationActive,
        })}>
        Saved
      </div>
      <div
        className={classNames('notification', {
          active: isErrorNotificationActive,
        })}>
        Invalid url address
      </div>
      <input
        className="redirect__input"
        placeholder="Set redirect address"
        value={redirectUrlInput || ''}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleSave}
      />
      <div
        className="redirect__tip-button"
        onClick={() => {
          toggleIsTipActive();
        }}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1114_3137)">
            <path
              d="M10.0001 18.3332C14.6026 18.3332 18.3334 14.6023 18.3334 9.99984C18.3334 5.39734 14.6026 1.6665 10.0001 1.6665C5.39758 1.6665 1.66675 5.39734 1.66675 9.99984C1.66675 14.6023 5.39758 18.3332 10.0001 18.3332Z"
              stroke="#878787"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.5 7.5C7.5 4.58333 12.0833 4.58333 12.0833 7.5C12.0833 9.58333 10 9.16667 10 11.6667M10 15.0083L10.0083 14.9992"
              stroke="#878787"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1114_3137">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <p
        className={classNames('redirect__tip', {
          active: isTipActive,
        })}>
        Set your own redirect page instead of default. Just paste here the link
        to the page you want to show the user after they submit your form.{' '}
        <a href="https://endpoint.space/success">Default redirection url</a>
      </p>
    </div>
  );
};
