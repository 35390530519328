import { useState, ChangeEvent, FormEvent, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { useCurrentUser } from 'hooks/useCurrentUser';

import { ENDPOINTS } from 'graphql/queries';
import { CLAIM_ENDPOINT } from 'graphql/mutations';

import { magic } from 'utils/magic';

export const Login = () => {
  const { email, endpointId } = useParams();

  const [claimEndpoint] = useMutation(CLAIM_ENDPOINT, {
    variables: { where: { id: endpointId } },
    refetchQueries: [ENDPOINTS],
  });

  const [disabled, setDisabled] = useState(false);
  const [emailInput, setEmailInput] = useState(email || '');

  const [, setUser] = useCurrentUser();

  const emailInputHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEmailInput(e.target.value);
    },
    [setEmailInput]
  );

  const loginHandler = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        setDisabled(true);
        await magic.auth.loginWithMagicLink({
          email: emailInput,
        });

        const userMetadata = await magic.user.getMetadata();
        setUser({
          data: userMetadata,
          didToken: await magic.user.generateIdToken({ lifespan: 1800 }),
          loading: false,
        });

        if (endpointId) {
          try {
            claimEndpoint();
          } catch {}
        }
      } catch (error) {
        setDisabled(false);
      }
    },
    [emailInput, setUser, endpointId, claimEndpoint]
  );

  return (
    <div className="login block">
      <h1 className="login__title">Welcome🖖</h1>
      <form className="login__form" onSubmit={loginHandler}>
        <input
          name="Email"
          placeholder="Email address"
          className="login__input"
          value={emailInput}
          disabled={disabled}
          onChange={emailInputHandler}
        />
        <button className="login__submit gradient-button" type="submit">
          <span>Log in / Sign up</span>
        </button>
      </form>
    </div>
  );
};
