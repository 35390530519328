import { gql } from '@apollo/client';

export const DELETE_EMAIL = gql`
  mutation DeleteEmail(
    $where: EndpointNotificationDestinationWhereUniqueInput!
  ) {
    deleteEndpointNotificationDestination(where: $where) {
      id
    }
  }
`;
