import { gql } from '@apollo/client';

export const ENDPOINTS = gql`
  query Endpoints {
    endpoints {
      id
      name
      redirectUrl
    }
  }
`;
