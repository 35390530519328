import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import classNames from 'classnames';

import { useWindowSize } from 'hooks/useWindowSize';

import { slideToggle } from 'utils/slideToggle';

import { SUBMISSIONS } from 'graphql/queries';
import { DELETE_SUBMISSION } from 'graphql/mutations';

import arrowIcon from 'assets/dropdown-arrow.svg';

interface IProps {
  data: any;
  time: string;
  id: string;
  date: string;
}

interface Field {
  key: string;
  value?: string;
}

export const Submission = ({ data, time, id, date }: IProps) => {
  const { endpointId } = useParams();

  const { width } = useWindowSize();

  const [deleteSubmission] = useMutation(DELETE_SUBMISSION, {
    refetchQueries: [SUBMISSIONS],
    update: (cache, { data: { deleteRecord: deletedSubmission } }) => {
      const submissions = cache.readQuery({
        query: SUBMISSIONS,
        variables: { where: { endpointId } },
      }) as any;

      cache.writeQuery({
        query: SUBMISSIONS,
        variables: { where: { endpointId } },
        data: {
          records: submissions.records.filter(
            ({ id: submissionId }: IProps) =>
              submissionId !== deletedSubmission?.id
          ),
        },
      });
    },
  });

  const handleClick = useCallback(() => {
    try {
      deleteSubmission({
        variables: { where: { endpointId, date } },
        optimisticResponse: {
          deleteRecord: {
            id,
          },
        },
      });
    } catch {}
  }, [date, deleteSubmission, endpointId, id]);

  const parsedFields = useMemo(
    () =>
      Object.keys(data).reduce<Field[]>((acc, key) => {
        if (key === 'files' && typeof data[key] === 'object') return acc;

        acc.push({ key, value: data[key] });

        return acc;
      }, []),
    [data]
  );

  const [headerFields, bodyFields] = useMemo(() => {
    if (width > 720) return [parsedFields?.slice(0, 2), parsedFields?.slice(2)];

    return [parsedFields?.slice(0, 1), parsedFields?.slice(1)];
  }, [width, parsedFields]);

  // This code distributes the submission content equally to the left and right columns
  const [leftColumn, rightColumn] = useMemo(() => {
    let leftColumnContentLength = 0;
    let rightColumnContentLength = 0;

    return bodyFields?.reduce<Array<Field[]>>(
      (acc, field) => {
        if (leftColumnContentLength <= rightColumnContentLength) {
          acc[0].push(field);
          leftColumnContentLength += field?.value?.length || 0;
        } else {
          acc[1].push(field);
          rightColumnContentLength += field?.value?.length || 0;
        }

        return acc;
      },
      // defining left & right column
      [[], []]
    );
  }, [bodyFields]);

  const handleToggle = (e: React.MouseEvent<HTMLLIElement>) => {
    slideToggle(e.currentTarget);
  };

  return (
    <li className="submission" id={`submission_${id}`} onClick={handleToggle}>
      <div
        className={classNames('submission__header', {
          'submission__header-extra-padding': !bodyFields?.length,
        })}>
        {bodyFields?.length ? (
          <img className="submission__arrow" src={arrowIcon} alt="open/close" />
        ) : null}
        {headerFields?.map((field) => (
          <div key={field.key} className={`submission__field header-field`}>
            <span className="header-field__title">{field.key}</span>
            <p className="header-field__content">{field?.value}</p>
          </div>
        ))}
        <div className="submission__info">
          <button className="submission__delete" onClick={handleClick}>
            Delete
          </button>
          <span className="submission__time">{time}</span>
        </div>
      </div>
      {!!bodyFields?.length && (
        <div className="submission__body">
          <div className="submission__column">
            {leftColumn?.map((field) => (
              <div key={field.key} className={`submission__field field`}>
                <span className={`field__title`}>{field.key}</span>
                <p className={`field__content`}>{field?.value}</p>
              </div>
            ))}
          </div>
          <div className="submission__column">
            {rightColumn?.map((field) => (
              <div key={field.key} className={`submission__field field`}>
                <span className={`field__title`}>{field.key}</span>
                <p className={`field__content`}>{field?.value}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </li>
  );
};
