import { useCallback, useState } from 'react';

import classNames from 'classnames';

import copyToClipboard from 'utils/copyToClipboard';

export const UrlCopy = ({ endpointUrl }: { endpointUrl: string }) => {
  const [isActive, setIsActive] = useState(false);

  const handleCopy = useCallback(
    () => copyToClipboard(endpointUrl),
    [endpointUrl]
  );

  const showNotification = useCallback(() => {
    if (isActive) return setIsActive(false);

    setIsActive(true);

    setTimeout(() => setIsActive(false), 3000);
  }, [isActive]);

  return (
    <div className="settings__url url">
      <button className="url__button gradient-button" onClick={handleCopy}>
        <div
          className={classNames('notification', {
            active: isActive,
          })}>
          Copied
        </div>
        <span className="url__text" onClick={showNotification}>
          Copy URL
        </span>
      </button>
      <div id="url" className="url__link">
        {endpointUrl}
      </div>
    </div>
  );
};
