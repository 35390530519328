import milkLogo from 'assets/milk-logo.svg';

export const DevelopedBy = ({ className }: { className?: string }) => {
  return (
    <footer className={`footer ${className ? className : ''}`}>
      <div>
        Developed by{' '}
        <a rel="noreferrer" target="_blank" href="https://milkandcartoons.com">
          Milk & Cartoons
        </a>
      </div>
      <a rel="noreferrer" target="_blank" href="https://milkandcartoons.com">
        <img src={milkLogo} alt="Milk&Cartoons" />
      </a>
    </footer>
  );
};
