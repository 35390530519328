import { Login } from 'components/auth/Login';

import logo from 'assets/logo.svg';

export const LoginPage = () => {
  return (
    <div className="login-container">
      <img className="login__logo" src={logo} alt="endpoint.space" />
      <Login />
    </div>
  );
};
