import { gql } from '@apollo/client';

export const ENDPOINT = gql`
  query Endpoint($where: EndpointWhereUniqueInput!) {
    endpoint(where: $where) {
      id
      name
      redirectUrl
    }
  }
`;
