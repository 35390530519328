import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { UrlCopy } from 'components/settings/UrlCopy';
import { EmailSetting } from 'components/settings/EmailSettings';
import { RedirectSettings } from 'components/settings/RedirectSettings';

import { ENDPOINTS } from 'graphql/queries';
import { DELETE_ENDPOINT } from 'graphql/mutations';

import { IEndpoint } from 'components/sidebar/Sidebar';

export const Settings = () => {
  const { endpointId } = useParams();
  const navigate = useNavigate();

  const { data } = useQuery(ENDPOINTS, { fetchPolicy: 'cache-only' });
  const endpoint: IEndpoint = data.endpoints.find(
    ({ id }: IEndpoint) => id === endpointId
  );

  const [deleteEndpointMutation] = useMutation(DELETE_ENDPOINT, {
    update: (cache, { data: { deleteEndpoint } }) => {
      cache.writeQuery({
        query: ENDPOINTS,
        data: {
          endpoints: data.endpoints.filter(
            ({ id }: IEndpoint) => id !== deleteEndpoint.id
          ),
        },
      });
    },
  });

  const deleteEndpoint = useCallback(() => {
    try {
      deleteEndpointMutation({
        variables: { where: { id: endpointId } },
        optimisticResponse: { deleteEndpoint: { id: endpointId } },
      });

      navigate('/');
    } catch {}
  }, [deleteEndpointMutation, endpointId, navigate]);

  return (
    <aside className="settings block">
      <div className="settings__header">
        <h2 className="settings__title title">
          <span className="settings__name">{endpoint?.name}</span>{' '}
          <span>settings:</span>
        </h2>
        <button className="settings__delete" onClick={deleteEndpoint}>
          Delete endpoint
        </button>
      </div>
      <div className="settings__body">
        <div className="settings__row">
          <UrlCopy
            endpointUrl={`${
              process.env.REACT_APP_COLLECTOR_URL as string
            }/${endpointId}`}
          />
          <EmailSetting />
        </div>
        <div className="settings__row">
          <RedirectSettings />
        </div>
      </div>
    </aside>
  );
};
