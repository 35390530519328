export const slideToggle = (toggleContent: HTMLElement | null) => {
  if (!toggleContent) return;

  toggleContent.classList.toggle('active');

  if (toggleContent.style.maxHeight) {
    toggleContent.style.maxHeight = '';
  } else {
    toggleContent.style.maxHeight = toggleContent.scrollHeight + 'px';
  }
};
