import { useCallback, useState, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import classNames from 'classnames';

import { useToggle } from 'hooks/useToggle';

import { validateEmail } from 'utils/validateEmail';

import { EMAILS } from 'graphql/queries';
import { CREATE_EMAIL, DELETE_EMAIL } from 'graphql/mutations';

import closeIcon from 'assets/close-icon.svg';
import arrowIcon from 'assets/dropdown-arrow.svg';
import spinner from 'assets/spinner.svg';

export const EmailSetting = () => {
  const { endpointId } = useParams();

  const [emailInput, setEmailInput] = useState('');
  const [isInvalidEmail, setInvalidEmail] = useState(false);
  const { state: settingEmail, toggle: toggleSettingEmail } = useToggle();

  const { loading, data } = useQuery(EMAILS, {
    variables: { where: { endpointId: { equals: endpointId } } },
    skip: !endpointId,
  });

  const [deleteEmailMutation] = useMutation(DELETE_EMAIL, {
    update: (cache, { data: { deleteEndpointNotificationDestination } }) => {
      cache.writeQuery({
        query: EMAILS,
        variables: { where: { endpointId: { equals: endpointId } } },
        data: {
          endpointNotificationDestinations:
            data.endpointNotificationDestinations.filter(
              (email: { id: string }) =>
                email.id !== deleteEndpointNotificationDestination.id
            ),
        },
      });
    },
  });

  const [createEmailMutation, { loading: creating }] = useMutation(
    CREATE_EMAIL,
    {
      update: (
        cache,
        { data: { createEndpointNotificationDestination: email } }
      ) => {
        cache.writeQuery({
          query: EMAILS,
          variables: { where: { endpointId: { equals: endpointId } } },
          data: {
            endpointNotificationDestinations: [
              ...data.endpointNotificationDestinations,
              email,
            ],
          },
        });
      },
    }
  );

  const handleChange = useCallback((e: FormEvent<HTMLInputElement>) => {
    setEmailInput(e.currentTarget.value);
  }, []);

  const createEmail = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (!validateEmail(emailInput)) return setInvalidEmail(true);
      else setInvalidEmail(false);

      try {
        createEmailMutation({
          variables: {
            data: {
              endpointId,
              isVerified: true,
              type: 'EMAIL',
              value: emailInput,
            },
          },
          optimisticResponse: {
            createEndpointNotificationDestination: {
              id: 'temp' + Math.random(),
              value: emailInput,
            },
          },
        });

        setEmailInput('');
      } catch {}
    },
    [createEmailMutation, emailInput, endpointId]
  );

  const deleteEmail = useCallback(
    (id) => {
      try {
        deleteEmailMutation({
          variables: {
            where: { id },
          },
          optimisticResponse: {
            deleteEndpointNotificationDestination: {
              id,
            },
          },
        });
      } catch {}
    },
    [deleteEmailMutation]
  );

  const Emails = useCallback(() => {
    if (loading) return <img src={spinner} className="email__loading" />;
    if (!data || data?.endpointNotificationDestinations.length === 0) {
      return `You don't have setted up emails yet`;
    }

    return data?.endpointNotificationDestinations.map(
      ({ id, value }: { id: string; value: string }) => {
        return (
          <li key={id} className="email-list__item">
            <span>{value}</span>
            <img src={closeIcon} alt="" onClick={() => deleteEmail(id)} />
          </li>
        );
      }
    );
  }, [loading, data, deleteEmail]);

  return (
    <div className="settings__email email">
      <button
        className={classNames('email__button', 'gradient-button', {
          active: settingEmail,
        })}
        onClick={useCallback(toggleSettingEmail, [toggleSettingEmail])}>
        <div>
          <span>Set email for notification</span>
          <img src={arrowIcon} alt="" />
        </div>
      </button>
      <div
        className={classNames('email__list', 'email-list', {
          active: settingEmail,
        })}>
        <form onSubmit={createEmail}>
          <input
            className="email-list__input"
            placeholder="endpoint@example.com"
            onChange={handleChange}
            disabled={creating}
            value={emailInput}
          />
          <button type="submit" className="email-list__submit">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 12.2857L9.85714 15.1429L17 8"
                stroke="#878787"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </form>
        {isInvalidEmail ? (
          <span className="email-list__error">Email is invalid</span>
        ) : null}
        <ul className="email-list__list">
          <Emails />
        </ul>
      </div>
    </div>
  );
};
