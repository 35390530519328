import { gql } from '@apollo/client';

export const SUBMISSIONS = gql`
  query Submissions($where: WhereRecordsInput!) {
    records(where: $where) {
      id
      body
      createdAt
    }
  }
`;
