import { FormEvent, useState, useCallback, Fragment, useRef } from 'react';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import classNames from 'classnames';

import { useToggle } from 'hooks/useToggle';

import { ENDPOINTS } from 'graphql/queries';
import { UPDATE_ENDPOINT } from 'graphql/mutations';

interface IProps {
  id: string;
  name: string;
  redirectUrl: string;
}

export const Endpoint = ({ id, name, redirectUrl }: IProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { endpointId: currentId } = useParams();

  const [nameInput, setNameInput] = useState(name);
  const { state: editing, toggle: toggleEditing } = useToggle(false);

  const input = useRef<HTMLInputElement | null>(null);

  const { data } = useQuery(ENDPOINTS, { fetchPolicy: 'cache-only' });
  const [updateEndpoint] = useMutation(UPDATE_ENDPOINT, {
    update: (
      cache,
      {
        data: {
          updateEndpoint: { name: updatedName },
        },
      }
    ) => {
      cache.writeQuery({
        query: ENDPOINTS,
        data: {
          endpoints: [
            ...data.endpoints.filter(
              ({ id: endpointId }: IProps) => endpointId !== id
            ),
            { id, name: updatedName, redirectUrl },
          ],
        },
      });
    },
  });

  const handleChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      setNameInput(e.currentTarget.value);
    },
    [setNameInput]
  );

  const edit = useCallback(() => {
    toggleEditing();
    setTimeout(() => {
      input.current?.focus();
      input.current?.select();
    }, 0);
  }, [toggleEditing]);

  const selectEndpoint = useCallback(() => {
    if (id === currentId) return null;

    navigate(`/${id}`);
  }, [navigate, id, currentId]);

  const rename = useCallback(() => {
    try {
      updateEndpoint({
        variables: { where: { id }, data: { name: { set: nameInput } } },
        optimisticResponse: {
          updateEndpoint: {
            id,
            name: nameInput,
          },
        },
      });
    } catch {}

    toggleEditing();
  }, [id, nameInput, toggleEditing, updateEndpoint]);

  return (
    <li
      className={classNames('endpoints-list__item', 'endpoint', {
        active: matchPath(pathname, `/${id}`),
      })}
      onClick={selectEndpoint}>
      {editing ? (
        <form>
          <input
            ref={input}
            className="endpoint__input"
            value={nameInput}
            onChange={handleChange}
          />
          <button className="endpoint__save" type="submit" onClick={rename}>
            Save
          </button>
        </form>
      ) : (
        <Fragment>
          <span className="endpoint__name">{name}</span>
          <span className="endpoint__edit" onClick={edit}>
            Edit
          </span>
        </Fragment>
      )}
    </li>
  );
};
