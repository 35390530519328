import { Fragment, useRef, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { groupBy } from 'lodash';
import dayjs from 'dayjs';

import { Submission } from 'components/submissions/Submission';

import { useWindowSize } from 'hooks/useWindowSize';

import { SUBMISSIONS } from 'graphql/queries';

import dropdownArrow from 'assets/dropdown-arrow.svg';
import spinner from 'assets/spinner.svg';

interface ISubmission {
  id: string;
  createdAt: string;
  body: string;
}

interface IParsedSubmission {
  id: string;
  date: string;
  time: string;
  data: any;
  createdAt: string;
}

export const Submissions = () => {
  const { endpointId } = useParams();

  const { width, height } = useWindowSize();

  const submissionsListElement = useRef<HTMLDivElement | null>(null);

  const { loading, data } = useQuery(SUBMISSIONS, {
    variables: { where: { endpointId } },
    pollInterval: 10000,
    skip: !endpointId,
  });

  const parsedSubmissions: IParsedSubmission[] = useMemo(() => {
    if (!data) return [];
    return data.records.map((submission: ISubmission) => {
      const { id, createdAt, body } = submission;

      const date = dayjs(createdAt).format('DD.MM.YYYY');
      const time = dayjs(createdAt).format('HH:mm');

      return {
        id,
        date,
        time,
        createdAt,
        data: body ? JSON.parse(body) : '',
      };
    });
  }, [data]);

  const groupedByDate: { [date: string]: IParsedSubmission[] } = useMemo(
    () => groupBy(parsedSubmissions, (submission) => submission.date),
    [parsedSubmissions]
  );

  const submissionsList = useMemo(() => {
    return Object.keys(groupedByDate).map((date) => {
      return (
        <Fragment key={date}>
          <div className="submissions__date">{date}</div>
          <ul className="submissions__list-by-date">
            {groupedByDate[date].map(
              ({
                id,
                data: submissionData,
                time,
                createdAt,
              }: IParsedSubmission) => (
                <Submission
                  key={id}
                  id={id}
                  data={submissionData}
                  time={time}
                  date={createdAt}
                />
              )
            )}
          </ul>
        </Fragment>
      );
    });
  }, [groupedByDate]);

  const scrollToTop = useCallback(() => {
    submissionsListElement.current?.scroll({ top: 0, behavior: 'smooth' });
  }, []);

  let blockHeight = '';
  if (width >= 992) blockHeight = height - 40 - 297 + 'px';
  else if (width >= 576) blockHeight = height - 40 - 273 + 'px';
  else blockHeight = height - 304 + 'px';

  if (loading) {
    return (
      <section className="submissions block" style={{ height: blockHeight }}>
        <h2 className="submissions__title title">Your submissions:</h2>
        <img src={spinner} className="submissions__loading" />
      </section>
    );
  }

  return (
    <section className="submissions block" style={{ height: blockHeight }}>
      <h2 className="submissions__title title">Your submissions:</h2>
      <div className="submissions__list" ref={submissionsListElement}>
        {submissionsList}
        <div className="submissions__return">
          <img src={dropdownArrow} alt="" onClick={scrollToTop} />
        </div>
      </div>
    </section>
  );
};
