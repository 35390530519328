import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { withErrorBoundary, useErrorBoundary } from 'react-use-error-boundary';

import { DashboardPage } from 'pages/DashboardPage';
import { LoginPage } from 'pages/LoginPage';

import { Loading } from 'components/common/Loading';
import { Error } from 'components/common/Error';

import { UserProvider } from 'providers/UserProvider';

import { useAuth } from 'hooks/useAuth';

import createApolloClient from 'startup/createApolloClient';

const App = withErrorBoundary(() => {
  const [error] = useErrorBoundary();
  if (error) return <Error />;

  const { user, setUser } = useAuth();

  const client = useMemo(
    () => createApolloClient([user, setUser]),
    [setUser, user]
  );

  if (user.loading) return <Loading />;

  return (
    <div className="container">
      <ApolloProvider client={client}>
        <UserProvider value={[user, setUser]}>
          <Routes>
            <Route path="/login/:email/:endpointId" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/:endpointId" element={<DashboardPage />} />
            <Route index element={<DashboardPage />} />
          </Routes>
        </UserProvider>
      </ApolloProvider>
    </div>
  );
});

export default App;
