import { gql } from '@apollo/client';

export const CREATE_ENDPOINT = gql`
  mutation CreateEndpoint($data: EndpointCreateInput!) {
    createEndpoint(data: $data) {
      id
      name
      redirectUrl
    }
  }
`;
