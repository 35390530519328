import { Fragment, useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import classNames from 'classnames';

import { Header } from 'components/layout/Header';
import { Sidebar } from 'components/sidebar/Sidebar';
import { Settings } from 'components/settings/Settings';
import { Submissions } from 'components/submissions/Submissions';
import { Loading } from 'components/common/Loading';
import { DevelopedBy } from 'components/common/DevelopedBy';

import { useWindowSize } from 'hooks/useWindowSize';

import { ENDPOINTS } from 'graphql/queries';
import { CREATE_ENDPOINT } from 'graphql/mutations';

export const DashboardPage = () => {
  const { endpointId } = useParams();
  const { width, height } = useWindowSize();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const [isActiveBurger, setActiveBurger] = useState(false);

  useEffect(() => {
    setActiveBurger(false);
  }, [width, height]);

  const { loading, data } = useQuery(ENDPOINTS);
  const [createEndpoint, { loading: creating }] = useMutation(CREATE_ENDPOINT, {
    update: (cache, { data: { createEndpoint: endpoint } }) => {
      cache.writeQuery({
        query: ENDPOINTS,
        data: { endpoints: [...data.endpoints, endpoint] },
      });

      navigate(`/${endpoint}`);
    },
  });

  const handleClick = useCallback(() => {
    try {
      createEndpoint({
        variables: { data: { name: 'Endpoint 1' } },
      });
    } catch {}
  }, [createEndpoint]);

  if (loading) return <Loading />;

  if (!data?.endpoints?.length) {
    return (
      <Fragment>
        <Header
          isActiveBurger={isActiveBurger}
          setActiveBurger={setActiveBurger}
        />
        <div className="center-container center-container-with-header">
          <button
            className="create-endpoint-button gradient-button"
            disabled={creating}
            onClick={handleClick}>
            <span>Create new endpoint</span>
          </button>
        </div>
      </Fragment>
    );
  }

  if (
    !data.endpoints.find(
      (endpoint: { id: string }) => endpoint?.id === endpointId
    )
  ) {
    navigate(`/${data?.endpoints[0]?.id}`);
  }

  return (
    <Fragment>
      <Header
        isActiveBurger={isActiveBurger}
        setActiveBurger={setActiveBurger}
      />
      <div className={classNames('burger-menu', { active: isActiveBurger })}>
        <Sidebar />
        <DevelopedBy />
        <div className="burger-menu__background" />
      </div>
      <div className="grid">
        <div className="column">
          <Sidebar />
        </div>
        <div className="column">
          <Settings />
          <Submissions />
        </div>
      </div>
      <DevelopedBy />
    </Fragment>
  );
};
