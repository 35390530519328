import logo from 'assets/logo.svg';

export const Error = () => {
  return (
    <div className="error center-container">
      <h1 className="error__title" style={{ textAlign: 'center' }}>
        Some error has occurred. <br />
        Try to reload page.
      </h1>
      <img className="error__logo" src={logo} alt="endpoint.space" />
    </div>
  );
};
