import { ReactElement, FC } from 'react';

import { Context, UserContext } from 'utils/UserContext';

interface IProps {
  children: ReactElement;
  value: Context;
}

export const UserProvider: FC<IProps> = ({ children, value }) => {
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
