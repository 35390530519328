import { createContext, Dispatch } from 'react';
import { MagicUserMetadata } from 'magic-sdk';

export type Context = [IUser, Dispatch<React.SetStateAction<IUser>>];

export interface IUser {
  data: MagicUserMetadata | null;
  didToken: string | null;
  loading: boolean;
}

// @ts-ignore
export const UserContext = createContext<Context>(null);
