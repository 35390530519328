import { gql } from '@apollo/client';

export const EMAILS = gql`
  query Emails($where: EndpointNotificationDestinationWhereInput) {
    endpointNotificationDestinations(where: $where) {
      id
      value
    }
  }
`;
