import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { Context } from 'utils/UserContext';
import { magic } from 'utils/magic';

const httpLink = (token: string | null) =>
  new HttpLink({
    uri: process.env.REACT_APP_GATEWAY_URL as string,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  });

const errorLink = ([user, setUser]: Context) =>
  onError(({ graphQLErrors }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(async ({ message }) => {
        if (
          message ===
          'Context creation failed: Magic Admin SDK Error: [ERROR_DIDT_EXPIRED] DID Token has expired. Request failed authentication.'
        ) {
          setUser({
            ...user,
            didToken: await magic.user.generateIdToken({ lifespan: 1800 }),
          });
        }
      });
  });

const createApolloClient = (userContext: Context) => {
  const client = new ApolloClient({
    link: from([
      errorLink(userContext),
      httpLink(userContext[0]?.didToken || null),
    ]),
    cache: new InMemoryCache(),
  });

  return client;
};

export default createApolloClient;
