import { gql } from '@apollo/client';

export const CREATE_EMAIL = gql`
  mutation CreateEmail($data: CreateEndpointNotificationDestinationInput!) {
    createEndpointNotificationDestination(data: $data) {
      id
      value
    }
  }
`;
