import { useMemo, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { Endpoint } from 'components/sidebar/Endpoint';

import { useWindowSize } from 'hooks/useWindowSize';

import { ENDPOINTS } from 'graphql/queries';
import { CREATE_ENDPOINT } from 'graphql/mutations';

import spinner from 'assets/spinner.svg';

export interface IEndpoint {
  id: string;
  name: string;
  redirectUrl: string;
}

export const Sidebar = () => {
  const windowSize = useWindowSize();

  const { data } = useQuery(ENDPOINTS, { fetchPolicy: 'cache-only' });
  const [createEndpoint, { loading: creating }] = useMutation(CREATE_ENDPOINT, {
    update: (cache, { data: { createEndpoint: endpoint } }) => {
      cache.writeQuery({
        query: ENDPOINTS,
        data: { endpoints: [...data.endpoints, endpoint] },
      });
    },
  });

  const endpoints = useMemo(() => {
    return data.endpoints.map(({ id, name, redirectUrl }: IEndpoint) => (
      <Endpoint key={id} id={id} name={name} redirectUrl={redirectUrl} />
    ));
  }, [data]);

  const handleClick = useCallback(() => {
    try {
      createEndpoint({
        variables: {
          data: { name: `Endpoint ${endpoints.length + 1}` },
        },
      });
    } catch {}
  }, [createEndpoint, endpoints.length]);

  const height = windowSize.height - 122 - 40 + 'px';

  return (
    <aside className="sidebar block" style={{ height }}>
      <h2 className="sidebar__title title">Endpoints list:</h2>

      <ul className="sidebar__list">{endpoints}</ul>

      {creating ? <img src={spinner} className="sidebar__loading" /> : null}

      <div className="sidebar__footer">
        <button
          className="sidebar__button create-endpoint-button gradient-button"
          disabled={creating}
          onClick={handleClick}>
          <span>Create new endpoint</span>
        </button>
      </div>
    </aside>
  );
};
