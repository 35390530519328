import logo from 'assets/logo.svg';
import spinner from 'assets/spinner.svg';

export const Loading = () => {
  return (
    <div className="loading center-container">
      <img className="loading__logo" src={logo} alt="endpoint.space" />
      <img className="loading__spinner" src={spinner} alt="loading..." />
    </div>
  );
};
