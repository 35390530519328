import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';

import { magic } from 'utils/magic';

import { IUser } from 'utils/UserContext';

export const useAuth = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [user, setUser] = useState<IUser>({
    data: null,
    didToken: null,
    loading: true,
  });

  useEffect(() => {
    magic.user.isLoggedIn().then((isLoggedIn) => {
      if (isLoggedIn) {
        magic.user.getMetadata().then(async (userData) =>
          setUser({
            data: userData,
            didToken: await magic.user.getIdToken({ lifespan: 1800 }),
            loading: false,
          })
        );
      } else setUser({ data: null, didToken: null, loading: false });
    });
  }, []);

  const isLogin = useMemo(() => {
    return (
      !!matchPath(pathname, '/login') ||
      !!matchPath({ path: '/login/:email/:endpointId' }, pathname)
    );
  }, [pathname]);

  useEffect(() => {
    if (!user.data && !user.loading && !isLogin) navigate('/login');
  }, [user, navigate, isLogin]);

  useEffect(() => {
    if (isLogin && user.data) {
      navigate('/');
    }
  }, [user, navigate, isLogin]);

  return { user, setUser };
};
