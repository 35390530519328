import { useCallback } from 'react';

import classNames from 'classnames';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { useToggle } from 'hooks/useToggle';

import { magic } from 'utils/magic';

import logo from 'assets/logo.svg';
import dropdownArrow from 'assets/dropdown-arrow.svg';

interface IProps {
  isActiveBurger: boolean;
  setActiveBurger: any;
}

export const Header = ({ isActiveBurger, setActiveBurger }: IProps) => {
  const [user, setUser] = useCurrentUser();
  const { state: isAccountToggled, toggle: toggleAccount } = useToggle(false);

  const logout = useCallback(() => {
    magic.user.logout().then(() => {
      setUser({ data: null, didToken: null, loading: false });
    });
  }, [setUser]);

  return (
    <header className={classNames('header', { active: isActiveBurger })}>
      <img className="header__logo" src={logo} alt="endpoint.space" />
      <div
        className="header__burger"
        onClick={() => setActiveBurger(!isActiveBurger)}>
        <span></span>
        <span></span>
      </div>
      <div
        className={classNames('header__account', { active: isAccountToggled })}
        onClick={toggleAccount}>
        <span className="header__email">{user?.data?.email}</span>
        <img src={dropdownArrow} className="header__arrow" alt="" />
      </div>
      <ul
        className={classNames('header__account-menu', {
          active: isAccountToggled,
        })}>
        <li className="header__account-item" onClick={logout}>
          Log Out
        </li>
      </ul>
    </header>
  );
};
